<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Enkompass
Author URL: https://www.enkompass.net
========================================================================================== -->


<template>
  <div class="clearfix">
    <vs-input
      v-validate="'required|alpha_spaces|min:3'"
      data-vv-validate-on="blur"
      label-placeholder="Name"
      name="fullname"
      placeholder="Name"
      v-model="fullname"
      class="w-full" />
    <span class="text-danger text-sm">{{ errors.first('fullname') }}</span>

    <vs-input
      v-validate="'required|email'"
      data-vv-validate-on="blur"
      name="email"
      type="email"
      label-placeholder="Email"
      placeholder="Email"
      v-model="email"
      class="w-full mt-6" />
    <span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <vs-input
      ref="password"
      type="password"
      data-vv-validate-on="blur"
      v-validate="'required|min:6'"
      name="password"
      label-placeholder="Password"
      placeholder="Password"
      v-model="password"
      class="w-full mt-6" />
    <span class="text-danger text-sm">{{ errors.first('password') }}</span>

    <vs-input
      type="password"
      v-validate="'min:6|confirmed:password'"
      data-vv-validate-on="blur"
      data-vv-as="password"
      name="confirm_password"
      label-placeholder="Confirm Password"
      placeholder="Confirm Password"
      v-model="confirm_password"
      class="w-full mt-6" />
    <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>

    <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6">I accept the terms & conditions.</vs-checkbox>
    <vs-button  type="border" to="/pages/login" class="mt-6">Login</vs-button>
    <vs-button type="border" class="float-right mt-6" @click="registerUser" :disabled="!validateForm">Register</vs-button>
  </div>
</template>

<script>

import { Validator } from 'vee-validate';


// VeeValidate.Validator.extend('email', {
//   getMessage(field, args) {
//     return `The ${field} must be a valid email`;
//   },
//   validate(value, args) {
//     const EMAILREG = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
//     console.log(EMAILREG.test(value));
//     console.log(VeeValidate.Rules.email(value));
//     return VeeValidate.Rules.email(value) || EMAILREG.test(value);;
//   }
// });

export default {
  data () {
    return {
      fullname: '',
      email: '',
      password: '',
      confirm_password: '',
      isTermsConditionAccepted: true
    }
  },
  created () {
    // Validator.extend('email', {
    //   getMessage: field => `Enter a valid email, belonging to a law firm.`,
    //   validate: value => {
    //     return this.checkEmail(value);
    //   }
    // });
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.fullname !== '' && this.email !== '' && this.password !== '' && this.confirm_password !== '' && this.isTermsConditionAccepted === true
    }
  },
  methods: {
    checkEmail(email) {
      return this.lib.filter( itm => email.indexOf(itm) > -1 ).length > 0
    },
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    registerUser () {
      // If form is not validated or user is already login return
      if (!this.validateForm || !this.checkLogin()) return

      //Clear localstorage
      window.localStorage.clear();

      // Loading
      this.$vs.loading()

      const payload = {
        userDetails: {
          fullname: this.fullname,
          email: this.email,
          password: this.password,
          confirmPassword: this.confirm_password
        },
        notify: this.$vs.notify
      }
      this.$store.dispatch('auth/registerUserAmplify', payload)
      .then(resp => {
        this.$vs.loading.close()
        if(resp.message){
          this.$vs.notify({
            title: 'Success!',
            text: resp.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        }
      })
      .catch(error => {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  }
}
</script>
